
import { defineComponent, onMounted, ref } from "vue";
import { setCurrentPageBreadcrumbs } from "@/core/helpers/breadcrumb";
import { useRedirectInsegnanti } from "@/composables/redirectInsegnanti";

import services from "../../../axios/dbManag";

import isEnabled from "@/composables/isEnabled.js";

export default defineComponent({
  name: "formazione-persona",
  props: ["persona"],
  components: {},
  setup(props) {
    const formazione = ref<any[]>([]);
    const loaded = ref(false);
    const error = ref(false);
    services.dbManag
      .post(`/anagrafiche/persone/corsi_aggiornamento/list`, {
        id_persona: props.persona.id,
      })
      .then((res) => {
        res.data.results.forEach((corso) => {
          formazione.value.push(corso);
        });
        loaded.value = true;
      })
      .catch((e) => {
        error.value = true;
      });
    onMounted(() => {
      setCurrentPageBreadcrumbs("Formazione Persona", [
        "Lista Persone",
        "Dettaglio Persona",
      ]);
    });
    //funzione gestione anno
    const getArrayLastYear = (arrayToFilter, lastYear) => {
      const array = arrayToFilter.filter((item) => {
        return item.stagione === lastYear;
      });
      return array;
    };
    const getArrayOtherYears = (arrayToFilter, lastYear) => {
      const array = arrayToFilter.filter((item) => {
        return item.stagione != lastYear;
      });
      return array;
    };

    const { pagesLength, redirectInsegnanti, menuLoaded, hasBacheca } =
      useRedirectInsegnanti();

    return {
      getArrayLastYear,
      getArrayOtherYears,
      formazione,
      loaded,

      pagesLength,
      redirectInsegnanti,
      menuLoaded,
      hasBacheca,

      isEnabled,
    };
  },
});
